<script setup lang="ts">
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import { SlpSlider } from '@/Pages/the-slp/components/interfaces/TheSlp'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import TheImageWebpBig from '@/Components/the-image/TheImageWebpBig.vue'
import ComparisonIcon from '@/Components/the-comparison/ComparisonIcon.vue'
import { 
    userWishListLocationsIDs,
    openWishlistModal as wishlistModalOpen 
} from '@/Composables/wishlistLogic'
import LocationsAtUserWishLists from '@/Components/wishlist/LocationsAtUserWishLists.vue'

const props = defineProps<SlpSlider>()

const swiper = ref()

const openWishlistModal = ():void => {
    wishlistModalOpen()
}

</script>

<template>
    <div class="mb-5 border-0 text-center shadow-lg position-relative card-hostels-slider">
        <div class="card-img-overlay-top d-flex justify-content-end card-hostels-slider-control-panel">
            <LocationsAtUserWishLists 
                :user-wish-list-locations-i-ds="userWishListLocationsIDs" 
                :hostel-id="listing.id" 
                @open-wishlist-modal="openWishlistModal"
            />

            <ComparisonIcon :listing-id="listing.id" />
        </div>

        <Swiper
            :modules="[Navigation, Pagination]"
            :slides-per-view="1"
            :space-between="0"
            grab-cursor
            :pagination="false"
            navigation
            @swiper="swiper = $event"
        >
            <SwiperSlide v-for="(img, key) in images" :key class="h-100">
                <div class="w-100 full-card-wrap">
                    <TheImageWebpBig :image="img" class="full-card-img img-fluid w-100" />

                    <a
                        :href="listing.url"
                        class="tile-link"
                        target="_blank"
                        :title="listing.name"
                    />
                </div>
            </SwiperSlide>
        </Swiper>

        <div class="card-img-overlay-bottom z-index-20">
            <p class="h5 card-text text-white">
                {{ listing.name }}
            </p>
        </div>
    </div>
</template>

<style lang="scss">
.full-card-wrap {
    height: 300px;

    .full-card-img {
        height: 100%;
        object-fit: cover;
    }
}

@media (min-width: 768px) {
    .full-card-wrap {
        height: 410px;
    }
}

.locations-at-user-wishlists-icon-heart {
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-hostels-slider {
    &-control-panel {
        width: fit-content;
        margin-left: auto;
        
        .comparison-tooltip {
            right: 110px;
            padding: 10px 20px;
    
            &::before {
                right: 11%;
                left: auto;
            }
        }

        .locations-at-user-wishlists-tooltip {
            display: block; 
            right: 35px;
        
            &::before {
                left: 58%;
            }
        }
    }
}
</style>